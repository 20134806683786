<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: '',
  data() {
    return {
       
    }
  },
  components: {
    
  },
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  
</style>